import { formatGBP } from "./formatting";

/*
This part is not general purpose. It considers that we can't have more than 1 quarter/year or 12 months.
So 4 quarters won't be converted to 1 year or 24 months to 2 years.
*/

const month = {
  title: "Monthly",
  amount: "month",
};

const quarter = {
  title: "Quarterly",
  amount: "quarter",
};

const year = {
  title: "Yearly",
  amount: "year",
};

const monthMap = new Map([
  [1, month],
  [3, quarter],
  [12, year],
]);

const labels = (defaultOption) => ({
  month: (amount) => monthMap.get(amount) || defaultOption,
  quarter: (_) => quarter,
  year: (_) => year,
});

const normalizeOffering = (offer) => ({
  ...offer,
  interval_unit: offer.interval_unit.toLowerCase(),
});

const recurringOfferingStruct = (offering) => {
  const { interval_unit, interval_amount } = normalizeOffering(offering.data);
  const currentlabels = labels({
    title: `${interval_amount}-monthly`,
    amount: `${interval_amount}-months`,
  })[interval_unit];

  // This is a double check to avoid not existent units
  return currentlabels
    ? currentlabels(interval_amount)
    : {
        title: `${interval_amount} ${interval_unit}`,
        amount: `${interval_amount} ${interval_unit}`,
      };
};

const recurringOfferingDescription = (offering) => {
  const { title, amount } = recurringOfferingStruct(offering);
  return `${title} (rolling) - ${formatGBP(offering.data.price)}/${amount}`;
};

const recurringOfferingAmount = (offering) => {
  const { amount } = recurringOfferingStruct(offering);
  return `${formatGBP(offering.data.price)}/${amount}`;
};

const recurringOfferingTitle = (offering) =>
  recurringOfferingStruct(offering).title;

const recurringOfferingDuration = (offering, rolling = false) => {
  const { interval_unit, interval_amount } = normalizeOffering(offering.data);

  const currentLabels = labels({
    title: `Monthly`,
    amount: `${interval_amount} months`,
  })[interval_unit];

  // This is a double check to avoid not existent units
  const currentlabelsWithDefault = currentLabels
    ? currentLabels(interval_amount)
    : {
        title: `${interval_amount} ${interval_unit}`,
        amount: `${interval_amount} ${interval_unit}`,
      };

  function getDuration() {
    if (interval_amount === 3 && interval_unit === "month") {
      return "Quarterly";
    } else if (interval_amount === 12 && interval_unit === "month") {
      return "Yearly";
    } else
      return interval_amount > 1 &&
        interval_unit !== "quarter" &&
        interval_unit !== "year"
        ? currentlabelsWithDefault.amount
        : currentlabelsWithDefault.title;
  }

  return getDuration() + (rolling ? "(Rolling)" : "");
};

export {
  recurringOfferingDescription,
  recurringOfferingDuration,
  recurringOfferingAmount,
  recurringOfferingTitle,
};
